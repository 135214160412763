
// Scroll refresher (for pull to refresh)
.scroll-refresher {
  position: absolute;
  top: -60px;
  right: 0;
  left: 0;
  overflow: hidden;
  margin: auto;
  height: 60px;
  .ionic-refresher-content {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    color: $scroll-refresh-icon-color;
    text-align: center;

    font-size: 30px;

    .text-refreshing,
    .text-pulling {
      font-size: 16px;
      line-height: 16px;
    }
    &.ionic-refresher-with-text {
      bottom: 10px;
    }
  }

  .icon-refreshing,
  .icon-pulling {
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .icon-pulling {
    @include animation-name(refresh-spin-back);
    @include animation-duration(200ms);
    @include animation-timing-function(linear);
    @include animation-fill-mode(none);
    -webkit-transform: translate3d(0,0,0) rotate(0deg);
    transform: translate3d(0,0,0) rotate(0deg);
  }
  .icon-refreshing,
  .text-refreshing {
    display: none;
  }
  .icon-refreshing {
    @include animation-duration(1.5s);
  }

  &.active {
    .icon-pulling:not(.pulling-rotation-disabled) {
      @include animation-name(refresh-spin);
      -webkit-transform: translate3d(0,0,0) rotate(-180deg);
      transform: translate3d(0,0,0) rotate(-180deg);
    }
    &.refreshing {
      @include transition(-webkit-transform .2s);
      @include transition(transform .2s);
      -webkit-transform: scale(1,1);
      transform: scale(1,1);

      .icon-pulling,
      .text-pulling {
        display: none;
      }
      .icon-refreshing,
      .text-refreshing {
        display: block;
      }
      &.refreshing-tail {
        -webkit-transform: scale(0,0);
        transform: scale(0,0);
      }
    }
  }
}
.overflow-scroll > .scroll{
  &.overscroll{
    position:fixed;
  }
  -webkit-overflow-scrolling:touch;
  width:100%;
}

@-webkit-keyframes refresh-spin {
  0%   { -webkit-transform: translate3d(0,0,0) rotate(0); }
  100% { -webkit-transform: translate3d(0,0,0) rotate(180deg); }
}

@keyframes refresh-spin {
  0%   { transform: translate3d(0,0,0) rotate(0); }
  100% { transform: translate3d(0,0,0) rotate(180deg); }
}

@-webkit-keyframes refresh-spin-back {
  0%   { -webkit-transform: translate3d(0,0,0) rotate(180deg); }
  100% { -webkit-transform: translate3d(0,0,0) rotate(0); }
}

@keyframes refresh-spin-back {
  0%   { transform: translate3d(0,0,0) rotate(180deg); }
  100% { transform: translate3d(0,0,0) rotate(0); }
}

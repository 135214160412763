
/**
 * Typography
 * --------------------------------------------------
 */


// Body text
// -------------------------

p {
  margin: 0 0 ($line-height-computed / 2);
}


// Emphasis & misc
// -------------------------

small   { font-size: 85%; }
cite    { font-style: normal; }


// Alignment
// -------------------------

.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }


// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $base-color;
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;
  line-height: $headings-line-height;

  small {
    font-weight: normal;
    line-height: 1;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: $line-height-computed;
  margin-bottom: ($line-height-computed / 2);

  &:first-child {
    margin-top: 0;
  }

  + h1, + .h1,
  + h2, + .h2,
  + h3, + .h3 {
    margin-top: ($line-height-computed / 2);
  }
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: ($line-height-computed / 2);
  margin-bottom: ($line-height-computed / 2);
}

h1, .h1 { font-size: floor($font-size-base * 2.60); } // ~36px
h2, .h2 { font-size: floor($font-size-base * 2.15); } // ~30px
h3, .h3 { font-size: ceil($font-size-base * 1.70); } // ~24px
h4, .h4 { font-size: ceil($font-size-base * 1.25); } // ~18px
h5, .h5 { font-size:  $font-size-base; }
h6, .h6 { font-size: ceil($font-size-base * 0.85); } // ~12px

h1 small, .h1 small { font-size: ceil($font-size-base * 1.70); } // ~24px
h2 small, .h2 small { font-size: ceil($font-size-base * 1.25); } // ~18px
h3 small, .h3 small,
h4 small, .h4 small { font-size: $font-size-base; }


// Description Lists
// -------------------------

dl {
  margin-bottom: $line-height-computed;
}
dt,
dd {
  line-height: $line-height-base;
}
dt {
  font-weight: bold;
}


// Blockquotes
// -------------------------

blockquote {
  margin: 0 0 $line-height-computed;
  padding: ($line-height-computed / 2) $line-height-computed;
  border-left: 5px solid gray;
  
  p {
    font-weight: 300;
    font-size: ($font-size-base * 1.25);
    line-height: 1.25;
  }
  
  p:last-child {
    margin-bottom: 0;
  }

  small {
    display: block;
    line-height: $line-height-base;
    &:before {
      content: '\2014 \00A0';// EM DASH, NBSP;
    }
  }
}


// Quotes
// -------------------------

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}


// Addresses
// -------------------------

address {
  display: block;
  margin-bottom: $line-height-computed;
  font-style: normal;
  line-height: $line-height-base;
}


// Links
// -------------------------

a.subdued {
  padding-right: 10px;
  color: #888;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
  &:last-child {
    padding-right: 0;
  }
}

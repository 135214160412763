.school-info {
  .school-name {
    margin: 0;
  }

  .school-location {
    color: #666;
  }
}

.cameras {
  @include flex-wrap(wrap);
}

.camera-player {
  display: block;
  position: relative;
  cursor: pointer;
  max-width: $camera-image-width;
  max-height: $camera-image-height;
}

.camera-play-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../images/transparent_play.png') center center no-repeat;
}

.camera-image-container {
  height: 0;
  padding-bottom: $camera-image-size-ratio;
  background-color: #000;
}

.camera-image {
  display: block;
  max-width: 100%;
}
.logo {
  img {
    display: block;
    margin: 0 auto;
    width: 245px;
  }
}

.login-help .button {
  margin-right: 5px;
}
/*
 * For shame!
 *
 * This stylesheet exists to contain messy, hacky CSS; the kind of stuff
 * you write when you just can't fix that display bug any other way,
 * stuff that should be removed if a cleaner solution is found, or the
 * bug is fixed.
 *
 * Removing lines from this file is removing shame from the codebase,
 * so follow up on these when possible!
 *
 */

/*
 * iOS doesn't display the side-shadow properly, because the default
 * uses multiple shadows, one for each side.
 *
 * This fix uses one shadow that extends equally on both sides instead.
 *
 * See: https://github.com/driftyco/ionic/pull/3746
 */
$menu-side-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

/*
 * $ionicLoading causes issues with iOS with the Select/Copy/Paste
 * in text inputs, because the .loading-container element is
 * only being set to `visibility: hidden` which isblocking tap inputs.
 *
 * This fix sets `display: none` instead to completely stop it from interfering.
 *
 * See: https://github.com/driftyco/ionic/issues/3793
 */
.loading-container:not(.visible) {
  display: none;
}

/*
 * Webkit/Blink currently have a bug where children of flexbox do not respect
 * the parent's dimensions, causing overflow and inability to size the child
 * via percentage.
 *
 * This fix uses a trick with position absolute to give the child a point
 * of reference for sizing.
 *
 * See: https://github.com/philipwalton/flexbugs/issues/28
 *    & https://code.google.com/p/chromium/issues/detail?id=341310
 */
.login-content .logo {
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
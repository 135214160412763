.bar {
  &.bar-branded {
    @include bar-style($bar-branded-bg, $bar-branded-border, $bar-branded-text);
    &.bar-footer {
      background-image: linear-gradient(180deg, $bar-branded-border, $bar-branded-border 50%, transparent 50%);
    }
  }
}

.bar-branded {
  button {
    @include button-style($bar-branded-bg, $bar-branded-border, $bar-branded-active-bg, $bar-branded-active-border, $bar-branded-text);
    @include button-clear(#fff, $bar-title-font-size);
  }
}
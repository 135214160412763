.popup-body {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.popup-buttons {
  display: block;

  .button {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}